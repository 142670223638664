import React from 'react'

import { RecoilRoot } from 'recoil'
// import 'focus-visible'

import { Props } from './types'

export default (props: Props): JSX.Element => {
  const { element } = props

  return <RecoilRoot>{element}</RecoilRoot>
}
