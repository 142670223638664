import React from 'react'
// import Helmet from 'react-helmet'

import Header from 'src/storyblok/GlobalComponents/Header/Header'
import Footer from 'src/storyblok/GlobalComponents/Footer/Footer'
import { Props } from './types'
import * as styles from './Layout.module.scss'

export default ({ children, pageContext, path }: Props): JSX.Element => {
  const { globalData, story }: any = pageContext ? pageContext : {}
  const { header, footer }: any = globalData ? globalData : {}

  // const seo_data: any = seo ? seo.content : {}
  // const { seo_description, seo_title, seo_image } = getSeo(seo_data)

  return (
    <div id="site-wrapper" style={{ overflow: 'hidden' }}>
      {/* {header?.content && <Header blok={header.content} />} */}
      {/* {seo_title && (
          <Helmet
            titleTemplate={`%s — ${seo_title}`}
            defaultTitle={seo_title}
          />
        )} */}
      {/* <SEO
          title={story?.name && story?.slug !== 'home' ? story.name : ''}
          metaDescription={seo_description}
          image={seo_image}
        /> */}
      {children}
      {/* {footer?.content && <Footer blok={footer.content} />} */}
    </div>
  )
}
