module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Håkan Hellström","short_name":"Håkan Hellström","start_url":"/","background_color":"#f7cb5c","theme_color":"#0f0e0e","display":"standalone","icon":"src/images/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"8404ecd2353b7df070c2535d4cce037d"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-75757023-1","head":false,"exclude":["/editor/**"],"anonymize":false,"respectDNT":false,"pageTransitionDelay":0,"enableWebVitalsTracking":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-multi-pixels/gatsby-browser.js'),
      options: {"0":{"dev":false,"alias":"pixelA","pixelId":"1694159484148662","viewContent":true,"pageView":true},"1":{"dev":false,"alias":"pixelB","pixelId":"458693704516249","viewContent":true,"pageView":true},"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
